import React from "react";
import { Paper, Typography } from '@mui/material';
import Timespan from "./components/timespan";

const spanStyle = {
  fontWeight: "bold",
  fontSize: "medium",
};

export default function Address(props) {
  const { address } = props;
  return (
      <Paper elevation={10}>
        <div style={{padding: "10px", height: "190px", borderRadius: "5px", backgroundColor: "#F3F4F7", overflow: "hidden"}}>
        <Typography variant="h6" component="span">{props.caption}</Typography><br />
        <div  style={{height: "8px"}}/>
        {address ? address.name : ""}<br />
        {address ? address.address : ""}<br />
        {address ? address.zipcode : ""} <span style={spanStyle}>{address ? address.city : ""}</span>
        {address ? " (" + address.country + ")": ""}<br/>
        {address ? <Timespan from={address.from} till={address.till}/>
        :""}
        <div  style={{height: "8px"}}/>
        Reference: {props.reference}
        </div>
      </Paper>
  );
}
