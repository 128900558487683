import React, {useState} from "react";
import { TextField } from '@mui/material';
import SearchButtons from "../../components/searchButtons";

export default function Sidebar(props) {

    const [trackingid, setTrackingid] = useState("");

    const FilenoPressed = (e) => {
        if (e.keyCode === 13) {
            onSearch();
        }
    };

    const onClear = () => {
        setTrackingid('');
    }

    const onSearch = () => {
        const criteria = { trackingid };
        props.onSearch(criteria);
    };


  return (
    <div style={{ backgroundColor: "#F3F4F7", height: "100vh", padding: "10px", paddingTop: "100px" }}>
            <TextField
                id="trackingid"
                label="Tracking id"
                margin="normal"
                style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                }}
                value={trackingid}
                onChange={(e) => setTrackingid(e.target.value)}
                onKeyDown={(e) => FilenoPressed(e)}
                inputProps={{ minLength: 10, maxLength: 50 }}
            />
        <SearchButtons onClear={onClear} onSearch={onSearch} />
        </div>


  );
}
