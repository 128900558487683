import React from "react";
import { Button } from '@mui/material';

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchButtons(props) {
  return (
      <React.Fragment>
          <div
              style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "grid",
                  gridTemplateColumns: "50%  50%",
              }}
          >
              <div style={{ marginRight: "5px" }}>
                  <Button
                      onClick={props.onClear}
                      variant="outlined"
                      color="primary"
                      startIcon={<ClearIcon />}
                      style={{ width: "100%" }}
                  >
                      Clear
                  </Button>
              </div>
              <div style={{ marginLeft: "5px" }}>
                  <Button
                      onClick={props.onSearch}
                      variant="outlined"
                      color="primary"
                      startIcon={<SearchIcon />}
                      style={{ width: "100%" }}
                  >
                      Search
                  </Button>
              </div>
          </div>
      </React.Fragment>
  );
}
