import React from "react";
import Icon from '@mui/icons-material/Gite';
import CheckIcon from '@mui/icons-material/Check';

export default function DeliveryIcon(props) {
    return (
        <React.Fragment>
            <Icon sx={{ color: "#1976D2", fontSize: 25 }}/>
            {props.done && <CheckIcon sx={{ color: "green", fontSize: 25, position: "relative", top: "-15px", left: "-5px" }}  />}
        </React.Fragment>
        
    );
}
