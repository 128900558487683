import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import http from "../../services/httpService";
import Address from './address';
import Shipper from './shipper';
import Goods from './goods';
import OrderStatus from './orderstatus';
import { CssBaseline, Box, Grid, LinearProgress, Typography } from '@mui/material';
import { toast } from "react-toastify";
import Sidebar from './sidebar';
import './trackandtrace.css';

export default function TrackAndTrace(props) {
    let [params, setParams] = useSearchParams();
    let [loading, setLoading] = useState(false);
    let [order, setOrder] = useState();

    useEffect(async () => {
        let code = params.get("code");
        if (code) {
            getData(code)
        }
    }, [params]);

    const getData= async (trackingid)=> {
        setLoading(true);
        try {
            const response = await http.get(`/api/orders/${trackingid}`)
            if (Object.keys(response.data).length >0){
                console.log("Data:", response.data);
                setOrder(response.data);            
            }
            else{
                toast.info("Order not found.");    
            }
        } catch (error) {
            toast.warn("Could not load order. Please try again later.");
        }
        setLoading(false);
    }

    const onSearch = async (filter) => {
        const {trackingid} = filter;
        if (trackingid)getData(trackingid);
    };

    const loadingAddress = () => {
        if (order && order.legs) {
            return order.legs[0].pickup;
        }
    }

    const deliveryAddress = () => {
        if (order) {
            if (order.legs) {
                const { legs } = order;
                return legs[legs.length - 1].delivery;
            }
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container>
                <Grid item xs={2}>
                    <Sidebar onSearch={onSearch} />
                </Grid>
                <Grid item xs={8}>
                    <Box>
                        <div style={{ display: "flex", marginTop: "40px" }}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Typography variant='h4'>Alblas Track and Trace</Typography>
                                <Typography variant='h6'>{(order) ? order.fileno: ""}</Typography>
                            </div>
                            <div><img src={"../../logo.png"} width="200" alt="Alblas transport"/></div>
                        </div>
                        {loading && (<LinearProgress  />)}
                        {(order) ?
                            <div style={{margin:"20px"}}>
                                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                                    <OrderStatus legs={order.legs}/>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <Shipper caption="Shipper" client={(order) ? order.client : {}} reference={order.clientreference} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginTop: "5px", marginRight: "3px", width: '50%' }}>
                                        <Address caption="Pickup" address={loadingAddress()} reference={order.pickupreference} />
                                    </div>
                                    <div style={{ marginTop: "5px", marginLeft: "3px", width: '50%' }}>
                                        <Address caption="Delivery" address={deliveryAddress()} reference={order.deliveryreference}/>
                                    </div>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <Goods goods={(order) ? order.goods : []} />
                                </div>
                            </div>


                            : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </React.Fragment>);
}