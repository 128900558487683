import React from "react";
import { StepLabel } from '@mui/material';
import WarehouseIcon from './warehouseIcon';

export default function WarehouseStep(props) {
    const { leg } = props;

    const getDate = ()=> {
        const val = leg.delivery.ata || leg.delivery.eta;
        if (val){
            const prefix = (leg.delivery.ata)? "" : "ETA: ";
            const date = Date.parse(val);
            return prefix + new Intl.DateTimeFormat("nl-NL",  { dateStyle: 'short', timeStyle: 'short' }).format(date);
        }
        else  {
            return "Not planned";
        }
    }
    
    return (
        <StepLabel StepIconComponent={() => <WarehouseIcon done={leg.delivery.ata} />}>
            Cross dock<br />{leg.delivery.city}<br/>
            <span>{getDate()}</span>
        </StepLabel>
    )
}
