import React from "react";
import { StepLabel } from '@mui/material';
import PickupIcon from './pickupIcon';

export default function PickupStep(props) {
    const {leg} = props;

    const getDate = ()=> {
        const val = leg.pickup.ata || leg.pickup.eta;
        if (val){
            const prefix = (leg.pickup.ata)? "" : "ETA: ";
            const date = Date.parse(val);
            return prefix + new Intl.DateTimeFormat("nl-NL",  { dateStyle: 'short', timeStyle: 'short' }).format(date);
        }
        else  {
            return "Not planned";
        }
    }
    
    return (
            <StepLabel StepIconComponent={() => <PickupIcon done={leg.pickup.ata} />}>
                Pickup<br />
                {leg.pickup.city}<br />
                {getDate()}
                </StepLabel>

    )
}
