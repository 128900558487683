import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

const api = window.BASE_URL;

axios.defaults.baseURL = api;
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
  }

  return Promise.reject(error);
});


const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};

export default httpService;