import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./login";
import TrackAndTrace from "./scenes/orders/trackandtrace";
import "react-toastify/dist/ReactToastify.min.css";

const theme = createTheme({
    typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/orders/trackandtrace",
        element: <TrackAndTrace />,
    },
]);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <header className="App-header">
                    <RouterProvider router={router} />
                    <ToastContainer autoClose={3000} />
                </header>
            </div>
        </ThemeProvider>
    );
}

export default App;
