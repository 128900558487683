import React from "react";
import { Paper, Typography } from '@mui/material';

const spanStyle = {
  fontWeight: "bold",
  fontSize: "medium",
};

export default function Shipper(props) {
  const { client } = props;
  return (
      <Paper elevation={10}>
        <div style={{padding: "10px", paddingLeft: "20px", backgroundColor: "#F3F4F7", overflow: "hidden", borderRadius: "7px"}}>
        <Typography variant="h6" component="span">{props.caption}</Typography>
        <div  style={{height: "8px"}}/>
        {client ? client.name : ""}<br />
        {client ? client.address : ""}<br />
        {client ? client.zipcode : ""} <span style={spanStyle}>{client ? client.city : ""}</span>
        {client ? " (" + client.country + ")": ""}
        <div  style={{height: "8px"}}/>
        Reference: {props.reference}
        </div>
      </Paper>
  );
}
