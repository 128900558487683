import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import WarehouseStep from './components/warehousestep';
import DeliveryStep from './components/deliverystep';
import PickupStep from './components/pickupstep';

// Toon van elk leg de lever gedeelte 
// plus van de eerste leg ook het afhalen
const mapToStep = (leg)=>{
    const ret = [];
    if (leg.previous===false) {
        ret.push(
            <Step key={"PU"}>
                <PickupStep leg={leg}/>
            </Step>
        )
    }
    ret.push(
        <Step key={leg.seqno + "-DL"}>
            {leg.next ? <WarehouseStep leg={leg}/>: <DeliveryStep leg={leg}/>}
        </Step>);    

    return ret;

        

}

export default function OrderStatus(props) {
    const { legs } = props;
    return (
        <Box sx={{ width: '100%' }}>
            {
                legs ? (
                    <Stepper activeStep={10} alternativeLabel>
                        {legs.map((l) => (
                            mapToStep(l)
                        ))}
                    </Stepper>

                ) : ""
            }
        </Box>
    );
}
