function init() {}

function log(error) {
  console.error(error);
}

const logService =  {
    init,
    log  
}

export default logService;
