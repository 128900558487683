import React from "react";
import { format } from "date-fns";

export default function Timespan(props) {

    const date = (dateValue) => {
        if (!dateValue) return "";
        const d = Date.parse(dateValue);
        if (isNaN(d)) { return "" };
        return format(d, 'dd-MM-yyyy');
    }

    const time = (dateValue) => {
        if (!dateValue) return "";
        const d = Date.parse(dateValue);
        if (isNaN(d)) { return "" };
        return format(d, 'HH:mm');
    }

    const duration = (from, till) => {
        if (!from) return "";
        if (!till) return "";
        const rawFrom = time(from);
        const rawTill = time(till);
        if (rawFrom === "00:00" && rawTill ==="00:00" ){
            return "";
        }
        if (rawFrom === rawTill ){
            return rawFrom;
        }
        const ret = rawFrom + ' - ' +  rawTill;
        return ret;
    }

    return (
        <React.Fragment>
            <span>Date: </span>
            {date(props.from)}&nbsp;&nbsp;
            {duration(props.from, props.till)}
        </React.Fragment>
    )
}



